.italics {
  font-style: italic;
  font-weight: 800; 
  display: inline;
  /* */
  color: red;
}

#navbar {
  background-color: #003d99 !important;
}

.dropdown-menu.dropdown-info .dropdown-item:hover, .dropdown-menu.dropdown-info .dropdown-item:focus {
  background-color: #003d99 !important;
}

.navbar .navbar-nav .dropdown-menu {
  border-radius: 5px;
}

#bold {
  font-style: bold;
  font-size: 12px;
}

.nav-item:hover {
  cursor: pointer;
}

.logo {
  width: 60px;
  margin-right: 10px;
}

.navbar .navbar-brand {
  padding: 0px;
  text-transform: capitalize;
  font-size: 40px;
}

