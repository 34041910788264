.App {
  text-align: center;
  /* must be added for the width below 772px whatever */
}

@media (max-width: 767px) {
  .ticker {
    display: none;
  }

  .success-ticker {
    display: none;
  }

  .cards {
    display: block;
    margin-top: 6rem;
    width: 100%;
  }

  .cards .card {
    width: 35rem;
    height: auto;
    margin: 15px auto;
  }

  .card .card-text {
    font-size: 15px;
  }

  #title {
    font-size: 17px;
  }

  #container {
    margin: 0px auto;
    padding: 0px;
    /* min-width: 60rem; */
  }

  .success-header {
    max-width: 100%;
    margin-top: 65px;
  }

  .success {
    max-width: 100%;
  }



}

@media (max-width: 600px) {
  .App {
    max-width: 100rem;
    overflow-x: hidden;
  }

  .ticker {
    display: none;
  }

  .page-header {
    overflow-y: scroll;
    max-width: 90rem;
  }

  .cards {
    display: inline-block;
    margin-top: 5rem;
    width: 100%;
  }

  .cards .card {
    max-width: 32rem;
    height: auto;
  }

  #container {
    margin: 0px 15px;
    padding: 0px;
    min-width: 35rem;
  }

  .card .card-text {
    font-size: 14px;
  }

  #title {
    font-size: 16px;
  }

  .navbar .navbar-brand {
    font-size: 20px;
  }

  .italics {
    display: none;
  }

  .success-header {
    max-width: 100%;
    margin-top: 65px;
  }

  .success {
    max-width: 100%;
  }

}

@media (max-width: 500px) {
  /* .App {
    max-width: 100rem;
    overflow-x: hidden;
  }

  .ticker {
    display: none;
  } */

  .page-header {
    max-height: 100%;
  }

  .cards {
    display: inline-block;
    margin-top: 5rem;
    width: 100%;
  }

  .cards .card {
    max-width: 20rem;
    height: auto;
  }

  #container {
    margin: 0px 15px;
    padding: 0px;
    min-width: 35rem;
  }

  #contact-us-section {
    height: 80vh;
  }

  .address {
    font-size: 18px;
    margin: 5px 20px;
  }

  .content {
    width: 100%;
  }

  .mapouter {
    width: 350px;
  }

  #gmap_canvas  {
    width: 350px;
  }

  .contact-text {
    font-size: 27px;
  }

  .contact-btn {
    font-size: 14px;
  }

  .success-header {
    max-width: 100%;
    margin-top: 65px;
  }

  .success {
    max-width: 100%;
  }
}


.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.ticker {
  margin-top: 60px;
  height: 60px;
}

#success-ticker {
  margin-top: 0px !important;
}


.promotions {
  font-weight: 500;
  color: black;
}

.icon {
  margin: 0 60px;
}





