#contact-us-section {
  height: 90vh;
}

.mapouter {
  position:relative;
  /* text-align:right; */
  height:340px;
  width:700px;
  margin: 0 auto;
}

.gmap_canvas {
  overflow:hidden;
  background:none!important;
  height:340px;
  width:700px;
}

.address {
  color: lightblue;
  margin: 0 auto;
  font-weight: 500;
  font-size: 30px;
}

.address a {
  text-decoration: none;
  font-weight: 500;
}

.content {
  width: 700px;
  margin: 0 auto;
  text-align: left;
}

.contact-us {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: space-around;
}

.contact-btn {
  margin-left: 10px;
  background-color: lightblue;
  color: black;
  border: lightblue;
}

.contact-btn:hover {
  margin-left: 10px;
  background-color: #002a69;
  color: lightblue;
  border: black;
}

.contact-text {
  color: lightblue;
  margin: 0px;
  font-weight: 500;
  font-size: 30px;
}

#add-info {
  min-height: 80px;
  width: 16rem;
  margin: 5px;
}

#time-info {
  /* min-height: 80px; */
  max-width: 7rem;
  margin: 5px;
}
