.section {
  background-color: #002a69;
  padding: 10px 0;
  height: auto;
}

.section-dark {
  background-color: black;
}

.title {
  color: white;
  margin: 15px 0;
  font-weight: 500;
}

.subtitle {
  margin: 25px 40px;
  color: lightgray;
  text-align: left;
  font-weight: 500;
}

#section-container {
  display:flex;  
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
  color: white;
  margin: 0 30px;
}

#detcolor {
  color: red;
  font-size: 18px;
  text-align: left;
  font-weight: 500;
}

.class-card {
  font-size: 18px;
  padding: 20px;
  border: 1px solid white;
  border-radius: 10px;
  max-width: 300px;
  min-height: 350px;
  position: relative;
  margin: 15px 0px;
}

.class-card .bottom-container {
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;;
}

.class-card .other {
  font-size: 18px;
  text-align: left;
  font-weight: 500;
}

.desc {
  font-size: 16px;
  text-align: left;
  margin: 15px 0;
  font-weight: 500;
}

#classes {
  margin-top: 0px;
  font-size: 20px;
  font-weight: 800;
}

.register-dark {
  margin-top: 20px;
  background-color: #003d99 !important;
  border: #003d99 !important;
  margin-bottom: 20px;
}

.register {
  margin-top: 20px;
  background-color: black !important;
  border: black !important;
  color: white;
  margin-bottom: 20px;
}

.register:hover, .register-dark:hover {
  opacity: .8;
}

.form-label {
  border-bottom: 1px solid gray;
  width: 100%;
  margin-left: 5px;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

/* FORM STYLING */
#courselabel {
  border-bottom: 1px solid gray;
}

.form-label {
  margin-left: 0px;
}

#registration {
  font-weight: 800;
  margin-left: 30%;
}

.modal-header {
  display: inline-flex !important;
  width: 100%; 
}

.form-control {
  color: black;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray;
  opacity: 1; /* Firefox */
}

label {
  color: black;
  font-weight: 500;
}

.undercaption {
  font-size: 12px;
  text-align: right;
  margin-right: 5px;
}

#gray:disabled, #gray[disabled], #gray.disabled {
  opacity: 0.8;
  color: black;
  background-color: gray;
}

#mailModal {
  padding-top: 15%;
}
