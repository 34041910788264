.website-heading {
  border-radius: 10px;
  background-color: #363636;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  opacity: .9;
  font-size: 60px;
  font-weight: 600;
  display: none;
  /* margin-bottom: 40px; */
}

.caption {
  display: none;
  margin-top: 0px;
  margin-bottom: 60px;
  font-size: 16px;
  padding: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #363636;
  opacity: .9;
  color: #ed0c1b;
  font-weight: 800; 
}

.card-title {
  font-size: 22px;
  font-weight: 500;
  color: white;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
}

#title {
  margin-bottom: 15px;
}

.card .card-text {
  font-size: 18px;
  color: white;
  font-weight: 500;
}

.cards {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

}

.cards .card {
  background-color: #003d99;
  color: white;
  opacity: .9;
  margin: 10px;
  width: 20rem;
  height: 33rem;
}

.page-header {
  z-index: 0; 
  min-height: 85vh;
}

#contact {
  font-weight: 800;
  margin-bottom: 0px;
  font-size: 12px;
}

#signup-btn {
  margin-top: 15px;
  margin-bottom: 20px;

}