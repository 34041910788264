.image {
  object-fit: cover;
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

#summary {
  font-size: 14px;
  margin-top: 15px;
  font-weight: 800;
}

.flex-div {
  display: inline-flex;
  width: 100%;
}

#names {
  font-size: 22px;
  margin-top: 5px;
  font-weight: 500;
}

#course {
  font-size: 20px;
  text-align: left;
  font-weight: 500;
  margin-top: 15px;
}

#course-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  margin-bottom: 10px;
}

#test-cards {
  position: relative;
  height: 475px;
}