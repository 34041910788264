.success {
  color: black;
  background: lightgreen;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 15px;
  font-weight: 500;
  font-size: 20px;
  width: 700px;
  margin: 0px auto;
}

.success-header {
  color: black;
  background: lightgreen;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 15px;
  width: 700px;
  margin: 0px auto;
  margin-top: 40px;
}